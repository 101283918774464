import React, { Fragment, useState, useEffect } from "react"
import classNames from "classnames"
import { Field, FieldArray, ErrorMessage } from "formik"
import styles from "./utils/form.module.scss"
import { isBrowser } from "../../../services/general"
/**
 ** Checkbox group linked in formik.
 ** Supported parameters:
 **
 **  {
 **               'name': String,
 **               'values': Object [],
 **               'label': String,
 **               'options': Object [],
 **               'onChange': Function,
 **               ...props compatible in Field Component of Formik
 **             }
 **/
const FormCheckbox = ({
  name,
  options,
  values,
  value = [],
  onChange,
  title,
  labelIconColor,
  labelIcon,
  hideOptional,
  helper,
  helperClassName,
  isRequired,
}) => {
  let isSpecificDimension = false

  const [windowDimensions, setWindowDimensions] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(
        window.innerWidth === 1536 && window.innerHeight === 730
      )
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const fieldValues = values || value
  // Custom handleChange of every checkbox in the group
  //
  // Note: Curried function: need the form from the Field and option (text from values)
  //       to return then (event) => { ... }
  const handleChange = (form, option) => (event) => {
    const { setFieldValue } = form
    let newValue = [...fieldValues]
    if (event.target.checked) newValue.push(option)
    else newValue = newValue.filter((element) => element !== option)
    setFieldValue(name, newValue)

    if (onChange) onChange(event, newValue)
  }

  const CheckBox = ({ form, option, index }) => {
    return (
      <Fragment>
        <input
          className="is-checkradio"
          type="checkbox"
          checked={fieldValues.find((element) => element === option)}
          onChange={handleChange(form, option)}
          id={`${name}[${index}]`}
          name={`${name}[${index}]`}
        />
        <label
          className={classNames(
            "checkbox-label",
            styles["formCheckbox__label"]
          )}
          for={`${name}[${index}]`}
        >
          <span className={windowDimensions === true ? "ml-2" : "ml-1"}>
            {option}
          </span>
        </label>
      </Fragment>
    )
  }

  return (
    <div className={classNames(styles["formCheckbox"])}>
      <label className={classNames("label has-text-weight-normal")}>
        {!!labelIcon && (
          <span className={`icon has-text-${labelIconColor}`}>{labelIcon}</span>
        )}
        <span> {title}</span>
        {!isRequired && !hideOptional && (
          <span className="has-text-grey is-italic"> (Optional)</span>
        )}
        {!!helper && (
          <span
            className={classNames(
              "help has-text-weight-normal",
              helperClassName
            )}
          >
            {helper}
          </span>
        )}
      </label>
      <FieldArray name={name}>
        {() =>
          options.map((option, index) => (
            <div key={index} className="mb-1">
              <Field>
                {({ form }) => (
                  <div className="field">
                    <CheckBox form={form} option={option} index={index} />
                  </div>
                )}
              </Field>
            </div>
          ))
        }
      </FieldArray>
      <p className="help is-danger mt-0 mb-1">
        <ErrorMessage name={name} />
      </p>
    </div>
  )
}

export default FormCheckbox
