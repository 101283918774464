import { useStaticQuery, graphql } from "gatsby"

const ALL_ADDRESSES = graphql`
  query allAddresses {
    allAirtableAddress {
      group(field: data___Name) {
        fieldValue
        edges {
          node {
            data {
              Cities {
                data {
                  Barangay_Names
                  Name
                }
              }
            }
          }
        }
      }
    }
  }
`

export const useAddresses = () => {
  const DATA = useStaticQuery(ALL_ADDRESSES)

  return DATA.allAirtableAddress.group.map((provItem) => {
    return {
      Province: provItem.fieldValue,

      Cities: provItem.edges[0].node.data.Cities.map(({ data }) => {
        return {
          City: data.Name,
          Barangays: data.Barangay_Names?.sort(),
        }
      }),
    }
  })
}