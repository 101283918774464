import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import DeliveryDetailsForm from "../../components/Epharmacy/DeliveryDetailsForm"

export default ({ location }) => (
  <Layout
    title="Checkout"
    subtitle="Please answer the following questions accurately. Your information will be used as a basis for your delivery."
    seoTitle="Checkout"
    process="epharmacy"
    display={{ footer: false, helpCenterBanner: false }}
    path={location?.pathname}
  >
    <Container isCentered desktop={6} fullhd={6}>
      <DeliveryDetailsForm
        backRoute="/epharmacy/order"
        nextRoute="/epharmacy/summary"
      />
    </Container>
  </Layout>
)
