import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faBuilding } from "@fortawesome/free-solid-svg-icons"

import styles from "../utils/personaldetails.module.scss"
import { ErrorMessage } from "formik"

const addressTypes = [
  {
    value: "Home",
    icon: faHome,
  },
  {
    value: "Office",
    icon: faBuilding,
  },
]

const initialHomeDeliveryAddressValues = 
  {
      label: "",
      streetAddress:"" ,
      province:{
          label: "",
          value: ""
        },
      city: {
        label:"",
        value:""
      },
      barangay: {
        label:"",
        value:""
      }
  }

const initialOfficeDeliveryAddressValues = 
  {
      label: "Rockwell Building, Hidalgo Drive",
      streetAddress:"Rockwell Building, Hidalgo Drive" ,
      province: {
          label: "Metro Manila",
          value: "Metro Manila"
        },
      city: {
          value:"Makati City",
          label: "Makati City"
      },
      barangay: {
        value:"Rockwell Center",
        label: "Rockwell Center"
      }
  }


const AddressTypeChoices = ({ addressTypeName, values, setFieldValue }) => {

  return (
    <div className="mb-1">
      <label>Address Type</label>
      <div
        className={classNames("tabs is-toggle is-toggle-rounded", styles["tabs"])}
      >
        <ul>
          {addressTypes.map((addressType) => (
            <li
              onClick={() => {
                setFieldValue("addressType", addressType.value)
                if(addressType.value === "Home"){
                  setFieldValue("deliveryAddress", initialHomeDeliveryAddressValues)
                } else {
                  setFieldValue("deliveryAddress", initialOfficeDeliveryAddressValues)
                }

              }} 
              className={getDisabledOfficeButtonClassNames(values, addressType)}
            >
              <a>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={addressType.icon} />
                </span>
                <span>{addressType.value}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <ErrorMessage name={addressTypeName}>
        {(error) => <div className="has-text-danger is-size-7">{error}</div>}
      </ErrorMessage>
    </div>
  )
}

const getDisabledOfficeButtonClassNames = (values, addressType) => {
  let disabledAddressTypes=["Office"]
  return classNames(`${values?.addressType === addressType.value && "is-active"}`, {
    [styles["disabled"]]: disabledAddressTypes.includes(addressType.value)
  })
}

export default AddressTypeChoices
