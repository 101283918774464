import React, { useState, useEffect, useRef } from "react"
import { useFormikContext } from "formik"
import FormInput from "elements/Form/FormInput"
import FormSelect from "elements/Form/FormSelect"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faHome, faBuilding } from "@fortawesome/free-solid-svg-icons"
import { useAddresses } from "../Elements/Address/hooks/useAddress"
//import address from "./utils/philippineLocations.json"

import handleScrollToError from "../Elements/Form/utils/handleScrollToError"

const INPUT_HOUSE_NUMBER_PLACEHOLDER = "Unit/House No."
const INPUT_STREET_PLACEHOLDER = "Building, Street"
const SELECT_PROVINCE_PLACEHOLDER = "Select province"
const SELECT_CITY_PLACEHOLDER = "Select city"
const SELECT_BARANGAY_PLACEHOLDER = "Select barangay"

const Address = ({
  isRequired,
  fieldNames = {},
  values = {},
  isNationwide,
  helper,
  setFieldValue,
}) => {
  const {
    houseNumber,
    addressType,
    streetAddress,
    province,
    city,
    barangay,
  } = fieldNames
  const address = useAddresses()
  const { deliveryAddress } = values
  const [listOfProvince, setProvinces] = useState([])
  const [listOfCity, setListOfCity] = useState([])
  const [listOfBarangays, setListOfBarangays] = useState([])

  const formik = useFormikContext()
  const sectionRef = useRef(null)
  const fieldName =
    addressType.split(".").length > 0 ? addressType.split(".")[0] : addressType

  const handleScrollCallback = () => {
    sectionRef.current.scrollIntoView({ block: "center" })
  }
  useEffect(() => {
    handleScrollToError({
      formikContext: formik,
      fieldName: fieldName,
      callback: handleScrollCallback,
    })
  }, [formik.submitCount, formik.isValid])

  useEffect(() => {
    initializeProvinces()
    const { Cities } = address.filter(
      (loc) => loc.Province === "Metro Manila"
    )[0]
    setListOfCity(sortCities(Cities))
    const cityFieldName = city.split(".")[1] || "city"
    setListOfBarangays(
      sortBarangays(deliveryAddress[cityFieldName]?.barangays || [])
    )
    //eslint-disable-next-line
  }, [])

  const initializeProvinces = () => {
    let options = address.map(remapProvinces)
    const metroManila = options.filter(({ value }) => value === "Metro Manila")
    if (isNationwide) setProvinces(options)
    else setProvinces(metroManila)
  }

  const remapProvinces = ({ Province }) => ({
    value: Province,
    label: Province,
  })

  const sortCities = (cities) => {
    const sortedCities = cities
      .map(({ City, Barangays }) => ({
        value: City,
        label: City,
        barangays: Barangays,
      }))
      .sort((a, b) => {
        return a.value > b.value ? 1 : -1
      })

    return sortedCities
  }
  const sortBarangays = (barangays) => {
    const sortedBarangays = barangays
      ?.map((barangay) => ({
        value: barangay,
        label: barangay,
      }))
      .sort((a, b) => (a.value > b.value ? 1 : -1))
    return sortedBarangays
  }

  const handleOnChange = ({ value }, setFieldValue) => {
    const { Cities } = address.filter((loc) => loc.Province === value)[0]
    setListOfCity(sortCities(Cities))
    setFieldValue(city || "city", { value: "", label: "" })
  }

  const handleOnCityChange = (value, setFieldValue) => {
    setListOfBarangays(sortBarangays(value ? value?.barangays : []))
    setFieldValue(barangay || "barangay", { value: "", label: "" })
  }

  return (
    <section ref={sectionRef}>
      {!!houseNumber ? (
        <FormInput
          name={houseNumber || "houseNumber"}
          label="House Number"
          helper={helper}
          placeholder={INPUT_HOUSE_NUMBER_PLACEHOLDER}
          isRequired={isRequired}
        />
      ) : null}
      <FormInput
        name={streetAddress || "streetAddress"}
        label="Street Address"
        helper={helper}
        placeholder={INPUT_STREET_PLACEHOLDER}
        isRequired={isRequired}
      />
      <FormSelect
        name={province || "province"}
        label="Province"
        value={
          (deliveryAddress.province || SELECT_PROVINCE_PLACEHOLDER).value !== ""
            ? deliveryAddress.province || SELECT_PROVINCE_PLACEHOLDER
            : null
        }
        placeholder={SELECT_PROVINCE_PLACEHOLDER}
        options={listOfProvince}
        onChange={handleOnChange}
        isRequired={isRequired}
      />
      <FormSelect
        // isClearable={true}
        name={city || "city"}
        label="City"
        placeholder={SELECT_CITY_PLACEHOLDER}
        value={
          (deliveryAddress.city || SELECT_CITY_PLACEHOLDER).value !== ""
            ? deliveryAddress.city || SELECT_CITY_PLACEHOLDER
            : null
        }
        isRequired={isRequired}
        options={listOfCity}
        isSelectable
        onChange={handleOnCityChange}
        isDisabled={(deliveryAddress.province || province).value === ""}
      />
      <FormSelect
        // isClearable={true}
        name={barangay || "barangay"}
        label="Barangay"
        placeholder={SELECT_BARANGAY_PLACEHOLDER}
        value={
          (deliveryAddress.barangay || SELECT_BARANGAY_PLACEHOLDER).value !== ""
            ? deliveryAddress.barangay || SELECT_BARANGAY_PLACEHOLDER
            : null
        }
        isRequired={isRequired}
        options={listOfBarangays}
        isSelectable
        isDisabled={(deliveryAddress.city || city).value === ""}
      />
    </section>
  )
}

export default Address
