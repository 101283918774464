import React, { Fragment } from "react"
import FormInput from "../Elements/Form/FormInput"
import FormDate from "../Elements/Form/FormDate"
import FormRadio from "../Elements/Form/FormRadio"

const PersonalDetails = ({ values = {}, onChange }) => (
  <Fragment>
    <FormInput
      name="firstName"
      label="First Name"
      placeholder="Juan"
      maxLength={50}
      isRequired
    />
    <FormInput
      name="lastName"
      label="Last Name"
      placeholder="Dela Cruz"
      maxLength={50}
      isRequired
    />
    <FormInput
      name="mobileNumber"
      label="Mobile Number"
      placeholder="09123456789"
      isRequired
      maxLength={11}
      type="phoneNumber"
    />
    <FormInput
      name="viberNumber"
      label="Viber Number"
      placeholder="09123456789"
      maxLength={11}
      type="phoneNumber"
      hideOptional
    />
    <FormInput
      name="emailAddress"
      label="Work Email"
      placeholder="jdelacruz@viatris.com"
      isRequired
    />
    <FormInput
      name="personalEmail"
      label="Personal Email"
      placeholder="jdelacruz@mail.com"
      isRequired
    />
    <FormInput
      name="designation"
      label="Designation"
      placeholder=""
      isRequired
    />
    <FormDate
      name="birthday"
      label="Birthday"
      config={{
        birthday: true,
      }}
      isRequired
      values={values.birthday}
      onChange={onChange}
    />
    <FormRadio
      title="Sex"
      name="sex"
      value={values.sex}
      options={["Male", "Female"]}
      onChange={onChange}
      isRequired
    />
  </Fragment>
)

export default PersonalDetails
