import React, { useEffect, useRef } from "react"
import { useFormikContext } from "formik"
import FormSelect from "elements/Form/FormSelect"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faHome, faBuilding } from "@fortawesome/free-solid-svg-icons"
import sites from "./utils/sites.json"

import handleScrollToError from "../Elements/Form/utils/handleScrollToError"

const SELECT_SITE_PLACEHOLDER = "Select Site"

const WorkAddress = ({
  isRequired,
  values,
  setFieldValue
}) => {
    const formik = useFormikContext()
    const sectionRef = useRef(null)

    const handleScrollCallback = () => {
        sectionRef.current.scrollIntoView({ block: "center" })
    }
    useEffect(() => {
        handleScrollToError({
        formikContext: formik,
        callback: handleScrollCallback,
        })
    }, [formik.submitCount, formik.isValid])

    const handleOnChange = (values) => {
        setFieldValue("deliveryAddress",values)   
    }

    return (
        <section ref={sectionRef}>
        <FormSelect
            isClearable={true}
            name="deliveryAddress"
            label="Site Address"
            placeholder= "Rockwell Building, Hidalgo Drive Makati City"
            value={values.deliveryAddress}
            isDisabled
            isRequired={isRequired}
            options={sites}
            onChange = {handleOnChange}
        />

        </section>
    )
}

export default WorkAddress
